import React, { useEffect } from "react";
import styled from "styled-components";
import bgloading from "../assets/images/bgloading.png";
import logo from "../assets/images/logotienthanh1.png";
import star from "../assets/images/star.png";
import logodatxanh from "../assets/Icons/logodatxanh.png";
import videobg from "../assets/video/videobackground.mp4"

import { useNavigate } from "react-router-dom";

function Loading() {
  const navigate = useNavigate();
  useEffect(() => {
    const timerId = setTimeout(() => {
      navigate("/tour360")
    }, 3000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  return (
    <Wrapper>
      {/* <div className="img-box">
        <img src={bgloading} alt="Loading" />
      </div> */}
      {/* <div
        className="container"
        style={{ backgroundImage: `url(${bgloading})`,backgroundPosition:"center", backgroundSize:"cover"  }}
      >
        <div className="border" >

        <div className="box" style={{ backgroundImage: `url(${bgloading})`,backgroundPosition:"center", backgroundSize:"cover" }}>
         
          <img className="logo" alt="Logo" src={logo} />
          <img alt="star" className="star" src={star} />
        </div>
        </div>

        
      </div> */}
      

      <div style={{width:"100%",height:"100%",backgroundImage: `url(${bgloading})`,backgroundPosition:"center", backgroundSize:"cover" }} >
        {/* <video src={videobg} autoPlay loop muted/> */}
        {/* <img alt="background" className="background" src={bgloading} /> */}

        <div className="content">
        <div
        className="container"
       
      >
        <div className="border" >

        <div className="box">
         
          <img className="logo" alt="Logo" src={logo} />
          {/* <img alt="star" className="star" src={star} /> */}
          <h3 className="name">TIÊN THANH</h3>
          <div class="loader">
  <div class="face">
    <div class="circle"></div>
  </div>
  <div class="face">
    <div class="circle"></div>
  </div>
</div>

        

        </div>
        </div>

        
      </div>

        </div>
      </div>

    </Wrapper>
  );
}
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  /* background-image: url("../assets/images/bgloadingkcntienthanh.png") center; */
  .background{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .name{
    color:white;
    margin-bottom: 50px;
    font-size: 26px;

    
  }
  .content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .img-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    width: 100%;
    height: 100%;
    z-index: 100;
    /* background-image: linear-gradient(to right bottom, #6a5af9, #f62682); */
    /* position: relative; */
    padding: 30px;
    
     
   
    .border{
      width: 100%;
      height: 100%;
    padding: 3px;
    /* z-index: -1; */
    border-radius: 10px;
    /* border: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(#CE8F31 100%, #F6E493 100%, #EFD45A 100%); */



     
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .box {
      width: 100%;
      height: 100%;
      /* position: relative; */
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: transparent;
      /* background-image: linear-gradient(to right bottom, #6a5af9, #f62682); */
      border-radius: 10px;
      /* border: 1px solid red; */

      &::before {
        /* content: "";
        position: absolute;
        inset: 0;
        z-index: 2;
        margin: -10px;
        background-image: linear-gradient(to right top, #2979ff, #07a787);
        border-radius: inherit; */
      }
      .logodatxanh {
        width: 20%;
        display: block;
      }
      .logo {
        display: block;
        width: 20.8%;
        margin-bottom: 10px;
      }
      .star {
        width: 3%;
        margin-top: 1.6%;
        display: block;
        animation: spinner 1s linear infinite;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .container {
      .box {
        .logodatxanh {
          width: 30% !important;
         
        }
        .logo {
          width: 40% !important;
        }
        .star {
          width: 7% !important;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .star {
      width: 10% !important;
    }
    .name{
    font-size: 22px;

    }

    .logo {
      width: 35% !important;
      
    }
  }
  @media screen and (max-width: 596px) {
    .logo {
      width: 30% !important;
     
    }
    .name{
    font-size: 16px;

    }
  }


  .loader {
    width: 8em;
    height: 8em;
    font-size: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader .face {
    position: absolute;
    border-radius: 50%;
    border-style: solid;
    animation: animate 3s linear infinite;
}

.loader .face:nth-child(1) {
    width: 100%;
    height: 100%;
    color: gold;
    border-color: currentColor transparent transparent currentColor;
    border-width: 0.2em 0.2em 0em 0em;
    --deg: -45deg;
    animation-direction: normal;
}

.loader .face:nth-child(2) {
    width: 70%;
    height: 70%;
    color: lime;
    border-color: currentColor currentColor transparent transparent;
    border-width: 0.2em 0em 0em 0.2em;
    --deg: -135deg;
    animation-direction: reverse;
}

.loader .face .circle {
    position: absolute;
    width: 50%;
    height: 0.1em;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: rotate(var(--deg));
    transform-origin: left;
}

.loader .face .circle::before {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    content: '';
    width: 1em;
    height: 1em;
    background-color: currentColor;
    border-radius: 50%;
    box-shadow: 0 0 2em,
                0 0 4em,
                0 0 6em,
                0 0 8em,
                0 0 10em,
                0 0 0 0.5em rgba(255, 255, 0, 0.1);
}

@keyframes animate {
    to {
        transform: rotate(1turn);
    }
}


`;

export default Loading;
