import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Guide from '../components/Guide'

import Logo from '../components/Logo'
// import Menu from '../components/Menu'
import Menusidebar from '../components/Menusidebar'
import { useTumysContext } from '../context/tumycontext'
import btnshow from '../assets/Icons/btnshow.png'
import Contactform from '../components/Contactform'
import Contactformenglish from '../components/Contactformenglish'





function SharedLayout() {
  const { HideGuide,
    ShowGuide,
    updateCurrentScene,currentscene,guide,ShowMenu,HideMenu,menu,english,setEnglish }  = useTumysContext();

    const [contact,setContact] = useState(false);
    
    const result = window.matchMedia("(max-width: 630px)");

    useEffect(()=>{
      ShowGuide();
 },[])


  return (
    <>
    
    
    

    {/* <Logo  /> */}
    {guide && <Guide ShowGuide={ShowGuide} HideGuide={HideGuide} /> }

    <Outlet />
    {menu &&  <Menusidebar  /> }
    {!menu && (

<div  style={{zIndex:"999"}} onClick={()=>{
  if(result.matches){
    ShowMenu()
  }
}} onMouseOver={()=>{
  ShowMenu()
}} className="btn-show">
<img  style={{width:"100%",height:"100%",zIndex:"999"}} src={btnshow} />

</div>
)}


<div className='btn-home'>
  <a href='https://tienthanhiz.com.vn'>
    
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_474_1183)">
<circle cx="32" cy="30" r="24" fill="white"/>
</g>
<path d="M22 31L31.293 21.707C31.4805 21.5195 31.7348 21.4142 32 21.4142C32.2652 21.4142 32.5195 21.5195 32.707 21.707L42 31H40V39C40 39.2652 39.8946 39.5195 39.7071 39.7071C39.5196 39.8946 39.2652 40 39 40H34V33H30V40H25C24.7348 40 24.4804 39.8946 24.2929 39.7071C24.1054 39.5195 24 39.2652 24 39V31H22Z" fill="#077983"/>
<defs>
<filter id="filter0_d_474_1183" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="4"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_474_1183"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_474_1183" result="shape"/>
</filter>
</defs>
</svg>
  </a>
</div>


<div  onClick={()=>setContact(true)} className='btn-contact'>
<svg   width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_474_1182)">
<circle cx="32" cy="30" r="24" fill="white"/>
</g>
<path d="M39.5 21.6666H24.5C23.837 21.6666 23.2011 21.93 22.7322 22.3989C22.2634 22.8677 22 23.5036 22 24.1666V35.8333C22 36.4963 22.2634 37.1322 22.7322 37.6011C23.2011 38.0699 23.837 38.3333 24.5 38.3333H39.5C40.163 38.3333 40.7989 38.0699 41.2678 37.6011C41.7366 37.1322 42 36.4963 42 35.8333V24.1666C42 23.5036 41.7366 22.8677 41.2678 22.3989C40.7989 21.93 40.163 21.6666 39.5 21.6666ZM39.25 25.6666L32.5833 31.5C32.4018 31.5935 32.2035 31.6502 32 31.6666C31.7965 31.6502 31.5982 31.5935 31.4167 31.5L24.75 25.6666C24.5842 25.523 24.4823 25.3193 24.4667 25.1006C24.4511 24.8818 24.523 24.6657 24.6667 24.5C24.8103 24.3342 25.0139 24.2323 25.2327 24.2167C25.4515 24.201 25.6676 24.273 25.8333 24.4166L31.9167 29.75L38 24.4166C38.081 24.3433 38.1761 24.2872 38.2794 24.2515C38.3827 24.2159 38.4921 24.2016 38.6011 24.2094C38.7101 24.2171 38.8164 24.2469 38.9136 24.2968C39.0108 24.3468 39.0969 24.4159 39.1667 24.5C39.259 24.5638 39.3358 24.6476 39.3914 24.7451C39.447 24.8426 39.4801 24.9513 39.4881 25.0633C39.4961 25.1753 39.4788 25.2876 39.4376 25.392C39.3964 25.4965 39.3323 25.5903 39.25 25.6666Z" fill="#077983"/>
<defs>
<filter id="filter0_d_474_1182" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="4"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_474_1182"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_474_1182" result="shape"/>
</filter>
</defs>
</svg>
</div>


{contact && english && (<Contactformenglish action={setContact} />)}
{contact && !english && (<Contactform action={setContact} />)}



    </>
  )
}

export default SharedLayout