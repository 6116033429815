import React, { useEffect, useState } from "react";
import styled from "styled-components";

import bgloading from "../assets/images/bgloading.png";
import logomenu from "../assets/images/logomenu.png";
import { Link } from "react-router-dom";
import icontour360 from "../assets/Icons/tour1.png";
import icontour360hover from "../assets/Icons/tour2.png";
import iconutility from "../assets/Icons/tool1.png";
import iconutilityhover from "../assets/Icons/tool2.png";
import houseicon from "../assets/Icons/house1.png";
import houseiconhover from "../assets/Icons/house2.png";
import locationicon from "../assets/Icons/pin1.png";
import locationiconhover from "../assets/Icons/pin2.png";
import btnhide from "../assets/Icons/btnhide.png";
import btnshow from "../assets/Icons/btnshow.png";
import closemobile from "../assets/images/closemobile.png";
import listmobilemenu from "../assets/images/listmobilemenu.png";
import { useTumysContext } from "../context/tumycontext";
import { useLocation } from "react-router-dom";

function Menusidebar() {
  const {
    HideGuide,
    ShowGuide,
    updateCurrentScene,
    currentscene,
    guide,
    HideMenu,
    ShowMenu,
    menu,
    english,
    setEnglish,
  } = useTumysContext();
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  // const [english,setEnglish]=useState(false);
  // const [hide,setHide] = useState(false);
  const [activemenu, setActiveMenu] = useState(1);
  const [listmenu, setListmenu] = useState(true);
  const location = useLocation();
  const result = window.matchMedia("(max-width: 630px)");

  const url = `https://360.goku.agency/tienthanh360`;
  // const url = `https://goku.agency/kcntienthanh`;
  //   useEffect(()=>{
  //  updateCurrentScene(`${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`)

  //   },[])

  useEffect(() => {
    // console.log("test")
    // if(currentscene==="")
    // console.log(currentscene===`${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`)
    datamenu.map((item) => {
      if (item.submenu) {
        item.submenu.map((itemsubmenu) => {
          if (
            itemsubmenu.scene === currentscene ||
            itemsubmenu.sceneEnglish === currentscene
          ) {
            setActiveMenu(itemsubmenu.id);
          }
        });
      } else {
        if (currentscene === item.scene || currentscene === item.sceneEnglish) {
          setActiveMenu(item.id);
        }
      }
    });
  }, []);

  const datamenu = [
    {
      id: 1,
      text: "TOÀN CẢNH 360",
      icon: icontour360,
      iconhover: icontour360hover,
      // link : '/kcntienthanh/toancanh360',
      hover: hover1,
      setHover: setHover1,
      nothover: false,
      scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_513_400m`,
      sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_513_400m`,

      notactive: "true",
    },
    {
      id: 2,
      text: "PHÂN KHU 360",
      icon: locationicon,
      iconhover: locationiconhover,
      // link : '/TienThanh/phankhu',
      hover: hover2,
      setHover: setHover2,
      nothover: true,
      notactive: "false",
      submenu: [
        {
          id: 1,
          text: "Phân khu A",
          scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_514_300m`,
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_514_300m`,
        },
        {
          id: 2,
          text: "Phân khu B",
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`,
          scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`,
        },
        {
          id: 3,
          text: "Phân khu C",
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_3_0511_300m`,
          scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_3_0511_300m`,
        },
        // {
        //   id: 4,
        //   text: "Phân khu D",
        //   sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`,
        //   scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`,
        // },
        {
          id: 5,
          text: "Khu nhà ở công nhân",
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_5_0440_300m`,
          scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_5_0440_300m`,
        },
      ],
    },
    {
      id: 3,
      text: "TIỆN ÍCH 360",
      icon: iconutility,
      iconhover: iconutilityhover,
      // link : '/TienThanh/tienich',
      hover: hover3,
      setHover: setHover3,
      nothover: true,
      notactive: "true",

      submenu: [
        {
          id: 9,
          text: "Cao tốc 5B",
          scene: `${url}/TienThanh360/index.html?startscene=scene_QL_10_0484_200m`,
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_QL_10_0484_200m`,
        },
        {
          id: 10,
          text: "Lạch Huyện",
          scene: `${url}/TienThanh360/index.html?startscene=scene_LH_0480_200m`,
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_LH_0480_200m`,
        },
      ],
    },
    // {
    //     id: 4,
    //     text: 'VỊ TRÍ',
    //     icon: locationicon,
    //     iconhover: locationiconhover,
    //     link : '/tumys/location',
    //     hover: hover4,
    //     setHover: setHover4

    // }
  ];

  const datamenuenglish = [
    {
      id: 1,
      text: "OVERVIEW 360",
      icon: icontour360,
      iconhover: icontour360hover,
      // link : '/TienThanh/toancanh360',
      hover: hover1,
      setHover: setHover1,
      nothover: false,
      scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_513_400m`,
      sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_513_400m`,
    },
    {
      id: 2,
      text: "ZONE 360",
      icon: locationicon,
      iconhover: locationiconhover,
      // link : '/TienThanh/phankhu',
      hover: hover2,
      setHover: setHover2,
      nothover: true,
      submenu: [
        {
          id: 1,
          text: "ZONE A",
          scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_514_300m`,
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_514_300m`,
        },
        {
          id: 2,
          text: "ZONE B",
          scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`,
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`,
        },
        {
          id: 3,
          text: "ZONE C",
          scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_3_0511_300m`,
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_3_0511_300m`,
        },
        // {
        //   id: 7,
        //   text: "ZONE D",
        //   scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`,
        //   sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`,
        // },
        {
          id: 5,
          text: "WORKERS HOUSING PLANNING LOCATION",
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_5_0440_300m`,
          scene: `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_5_0440_300m`,
        },
      ],
    },
    {
      id: 3,
      text: "UTILITY 360",
      icon: iconutility,
      iconhover: iconutilityhover,
      // link : '/TienThanh/tienich',
      hover: hover3,
      setHover: setHover3,
      nothover: true,

      submenu: [
        {
          id: 9,
          text: "5B Highway",
          scene: `${url}/TienThanh360/index.html?startscene=scene_QL_10_0484_200m`,
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_QL_10_0484_200m`,
        },
        {
          id: 10,
          text: "Lach Huyen Port",
          scene: `${url}/TienThanh360/index.html?startscene=scene_LH_0480_200m`,
          sceneEnglish: `${url}/TienThanh360English/index.html?startscene=scene_LH_0480_200m`,
        },
      ],
    },
    // {
    //     id: 4,
    //     text: 'VỊ TRÍ',
    //     icon: locationicon,
    //     iconhover: locationiconhover,
    //     link : '/tumys/location',
    //     hover: hover4,
    //     setHover: setHover4

    // }
  ];
  // useEffect(()=>{
  //     datamenu.forEach((item)=>{

  //         if(item.link === location.pathname){
  //             setActiveMenu(item.id)
  //         }
  //     })
  // },[])
  console.log(activemenu);

  return (
    <>
      {listmenu && (
        <Wrapper menu listmenu>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
              // marginLeft: "40px",
              height: "15%",
              // background: "red",
              cursor: "pointer",
              zIndex: "9999",
              padding: "16px",
            }}
            className="logo"
            onClick={() => {
              ShowGuide();
              console.log("show logo");
            }}
          >
            <img
              style={{ width: "85%", marginLeft: "6px" }}
              src={logomenu}
              alt="Logo"
            />
          </div>

          <ul className="menu-list">
            {!english
              ? datamenu.map((item, key) => {
                  return (
                    <>
                      <li
                        onMouseOver={() => {
                          item.setHover(true);
                        }}
                        onMouseLeave={() => {
                          item.setHover(false);
                        }}
                        onClick={() => {
                          if (result.matches) {
                            setListmenu(false);
                          }

                          if (item.id === 2) {
                            setActiveMenu(4);
                            // if (result.matches) {
                            //   setListmenu(false)
                            // }
                            if (item.submenu) {
                              if (english) {
                                updateCurrentScene(
                                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                                );
                              } else {
                                updateCurrentScene(
                                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                                );
                              }
                            }
                          } else if (item.id === 3) {
                            // if (result.matches) {
                            //   setListmenu(false)
                            // }
                            setActiveMenu(9);
                            if (item.submenu) {
                              if (english) {
                                updateCurrentScene(
                                  `${url}/TienThanh360English/index.html?startscene=scene_QL_10_0484_200m`
                                );
                              } else {
                                updateCurrentScene(
                                  `${url}/TienThanh360/index.html?startscene=scene_QL_10_0484_200m`
                                );
                              }
                            }
                          } else {
                            HideMenu();

                            setActiveMenu(item.id);
                            if (english) {
                              updateCurrentScene(item.sceneEnglish);
                            } else {
                              updateCurrentScene(item.scene);
                            }

                            // setListmenu(false);
                          }
                        }}
                        key={key}
                        className={
                          activemenu === item.id && !item.nothover
                            ? `menu-item active`
                            : `menu-item`
                        }
                      >
                        <div className="menu-link">
                          {item.hover || activemenu === item.id ? (
                            <img
                              className="iconhover"
                              src={item.iconhover}
                              alt="Icon"
                            />
                          ) : (
                            <img src={item.icon} alt="Icon" />
                          )}

                          <span
                            style={{ marginLeft: "8px" }}
                            className="menu-text"
                          >
                            {item.text}
                          </span>
                        </div>
                      </li>

                      {item.submenu?.map((itemsubmenu, index) => {
                        return (
                          <li
                            onClick={() => {
                              console.log(itemsubmenu.id);
                              setActiveMenu(itemsubmenu.id);
                              // console.log(activemenu)

                              HideMenu();

                              // setListmenu(false);
                              if (result.matches) {
                                setListmenu(false);
                              }

                              if (itemsubmenu.scene) {
                                if (english) {
                                  updateCurrentScene(itemsubmenu.sceneEnglish);
                                } else {
                                  updateCurrentScene(itemsubmenu.scene);
                                }
                              }
                            }}
                            key={index}
                            className={
                              activemenu === itemsubmenu.id
                                ? `menu-item submenu active`
                                : `menu-item submenu`
                            }
                          >
                            <div className="menu-link submenu">
                              <span
                                style={{ marginLeft: "8px" }}
                                className="menu-text submenu"
                              >
                                {itemsubmenu.text}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </>
                  );
                })
              : datamenuenglish.map((item, key) => {
                  return (
                    <>
                      <li
                        onMouseOver={() => {
                          item.setHover(true);
                        }}
                        onMouseLeave={() => {
                          item.setHover(false);
                        }}
                        onClick={() => {
                          if (result.matches) {
                            setListmenu(false);
                          }
                          if (item.id === 2) {
                            setActiveMenu(4);
                            if (item.submenu) {
                              if (english) {
                                updateCurrentScene(
                                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                                );
                              } else {
                                updateCurrentScene(
                                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                                );
                              }
                            }
                          } else if (item.id === 3) {
                            setActiveMenu(9);
                            if (item.submenu) {
                              if (english) {
                                updateCurrentScene(
                                  `${url}/TienThanh360English/index.html?startscene=scene_QL_10_0484_200m`
                                );
                              } else {
                                updateCurrentScene(
                                  `${url}/TienThanh360/index.html?startscene=scene_QL_10_0484_200m`
                                );
                              }
                            }
                          } else {
                            HideMenu();

                            setActiveMenu(item.id);
                            if (english) {
                              updateCurrentScene(item.sceneEnglish);
                            } else {
                              updateCurrentScene(item.scene);
                            }
                          }
                        }}
                        key={key}
                        className={
                          activemenu === item.id && !item.nothover
                            ? `menu-item active`
                            : `menu-item`
                        }
                      >
                        <div className="menu-link">
                          {item.hover || activemenu === item.id ? (
                            <img src={item.iconhover} alt="Icon" />
                          ) : (
                            <img src={item.icon} alt="Icon" />
                          )}

                          <span
                            style={{ marginLeft: "8px" }}
                            className="menu-text"
                          >
                            {item.text}
                          </span>
                        </div>
                      </li>

                      {item.submenu?.map((itemsubmenu, index) => {
                        return (
                          <li
                            onClick={() => {
                              setActiveMenu(itemsubmenu.id);
                              HideMenu();

                              if (result.matches) {
                                setListmenu(false);
                              }
                              if (itemsubmenu.scene) {
                                if (english) {
                                  updateCurrentScene(itemsubmenu.sceneEnglish);
                                } else {
                                  updateCurrentScene(itemsubmenu.scene);
                                }
                              }
                            }}
                            key={index}
                            className={
                              activemenu === itemsubmenu.id
                                ? `menu-item submenu active`
                                : `menu-item submenu`
                            }
                          >
                            <div className="menu-link submenu">
                              <span
                                style={{ marginLeft: "8px" }}
                                className="menu-text submenu"
                              >
                                {itemsubmenu.text}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </>
                  );
                })}
          </ul>

          <div
            onClick={() => {
              setEnglish(!english);
              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_513_400m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_513_400m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_513_400m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_513_400m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_514_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_514_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_514_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_514_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_3_0511_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_3_0511_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_3_0511_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_3_0511_300m`
                );
              }

              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_5_0440_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_5_0440_300m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_5_0440_300m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_5_0440_300m`
                );
              }

              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_QL_10_0484_200m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_QL_10_0484_200m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_QL_10_0484_200m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_QL_10_0484_200m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360/index.html?startscene=scene_LH_0480_200m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360English/index.html?startscene=scene_LH_0480_200m`
                );
              }
              if (
                currentscene ===
                `${url}/TienThanh360English/index.html?startscene=scene_LH_0480_200m`
              ) {
                updateCurrentScene(
                  `${url}/TienThanh360/index.html?startscene=scene_LH_0480_200m`
                );
              }
            }}
            className="btn-language notmobile"
          >
            <div className={!english ? "active" : ""}>VI</div>
            <div className={english ? "active" : ""}>EN</div>
          </div>

          <div
            onClick={() => {
              if (result.matches) {
                HideMenu();
              }
            }}
            onMouseOver={() => HideMenu()}
            className="btn-hide"
          >
            <img style={{ width: "100%", height: "100%" }} src={btnhide} />
          </div>
        </Wrapper>
      )}
      <WrapperMenu>
        <div className="mobile-menu">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "40%",
              // marginLeft: "40px",
              height: "90%",
              // background: "red",
              cursor: "pointer",
              zIndex: "9999",
              padding: "20px",
            }}
            className="logo logomobile"
            onClick={() => {
              if (result) ShowGuide();
              console.log("show logo");
            }}
          >
            <img style={{ width: "65%" }} src={logomenu} alt="Logo" />
          </div>
          {listmenu && (
            <div
              onClick={() => {
                setEnglish(!english);
                if (
                  currentscene ===
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_1_0434_cao_300m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_513_400m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_513_400m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_2_513_400m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_2_513_400m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_3_0511_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_3_0511_300m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_3_0511_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_3_0511_300m`
                  );
                }

                if (
                  currentscene ===
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_4_505_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_4_505_300m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_5_0440_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_5_0440_300m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360/index.html?startscene=scene_qqieycyjm_5_0440_300m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360English/index.html?startscene=scene_qqieycyjm_5_0440_300m`
                  );
                }

                if (
                  currentscene ===
                  `${url}/TienThanh360/index.html?startscene=scene_QL_10_0484_200m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360English/index.html?startscene=scene_QL_10_0484_200m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360English/index.html?startscene=scene_QL_10_0484_200m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360/index.html?startscene=scene_QL_10_0484_200m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360/index.html?startscene=scene_LH_0480_200m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360English/index.html?startscene=scene_LH_0480_200m`
                  );
                }
                if (
                  currentscene ===
                  `${url}/TienThanh360English/index.html?startscene=scene_LH_0480_200m`
                ) {
                  updateCurrentScene(
                    `${url}/TienThanh360/index.html?startscene=scene_LH_0480_200m`
                  );
                }
              }}
              className="btn-language"
            >
              <div className={!english ? "active" : ""}>VI</div>
              <div className={english ? "active" : ""}>EN</div>
            </div>
          )}

          {listmenu && (
            <div
              onClick={() => {
                setListmenu(false);
              }}
              style={{ height: "100%", display: "flex", alignItems: "center" }}
              className="closemobile"
            >
              <img style={{ width: "65%" }} src={closemobile} alt="Logo" />
            </div>
          )}

          {!listmenu && (
            <div
              onClick={() => {
                setListmenu(true);
              }}
              style={{ height: "100%", display: "flex", alignItems: "center" }}
              className="list"
            >
              <img style={{ width: "85%" }} src={listmobilemenu} alt="Logo" />
            </div>
          )}
        </div>
      </WrapperMenu>
    </>
  );
}
const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 99;
  /* display: grid; */
  grid-template-columns: 20.625% auto;
  height: 100vh;
  width: 22.5vw;
  /* align-items: center; */
  background: rgba(1, 82, 89, 0.6);
  animation: ${(props) => {
    if (props.menu) {
      return "show 0.5s linear";
    } else {
      return "hide 0.5s linear";
    }
  }};
  transition: all 0.5s linear;
  /* brand/02 */

  border-right: 1px solid #a6c93b;
  .mobile-menu {
    display: none;
  }
  .btn-hide {
    position: absolute;
    left: 88%;
    top: 1.5%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    /* background-color: red; */
  }

  .btn-language {
    display: flex;
    position: absolute;
    left: 5%;
    bottom: 3%;
    background-color: white;
    width: 26%;
    height: 5vh;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    gap: 4px;
    padding: 5px;
    cursor: pointer;
    div {
      background-color: white;
      width: 100%;
      border-radius: 10px;
      text-align: center;
    }
    .active {
      background-color: #077983 !important;
      color: white;
    }
  }
  .menu-list {
    list-style: none;
    margin-top: 14px;

    .menu-link {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 20px;
      /* background-color: #A6C93B; */
      /* border-top: 1px solid black; */
      font-weight: 700;
      margin-top: 14px;
      color: white;
      font-size: 14px;
      cursor: pointer;
      img {
        width: 28px;
      }
    }
    .menu-link.submenu {
      font-weight: 400;
      margin-top: 0;
      padding: 10px 50px;
    }
    .menu-item.active .menu-link {
      background: #a6c93b;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .menu-item:hover {
      background: #a6c93b;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  @media screen and (max-width: 1920px) {
    width: 19vw;
    .btn-language {
      height: 3.5vh;
      width: 37%;
    }
  }
  @media screen and (max-width: 1560px) {
    width: 22vw;
    .btn-language {
      height: 4.5vh;
      width: 37%;
    }
    .logo {
      /* height: 8%!important; */
    }
  }

  @media screen and (max-width: 1130px) {
    width: 29vw;
    .btn-language {
      height: 3.5vh;
      width: 30%;
    }
  }

  /* border-top: 2px solid;
  border-image-slice: 1;

  
 

  /* Tablet */
  @media screen and (max-width: 824px) {
    width: 32vw;
    .logo {
      padding: 10px !important;
      height: 12% !important;
      img {
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
    .menu-item:hover {
      background: transparent !important;
      box-shadow: none;
    }
    .btn-language {
      height: 3.5vh;
      width: 30%;
    }
    .menu-list {
      .menu-link.submenu {
        padding: 10px 30px 10px 50px;
      }
    }
  }
  /* Phone */
  @media screen and (max-width: 624px) {
    right: 0;
    width: 100%;
    /* bottom: 0!important; */
    /* bottom:8vh!important; */
    bottom: 0 !important;
    top: 15%;
    height: 95%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: none;

    .menu-item:hover {
      background: transparent !important;
      box-shadow: none;
    }
    .logo {
      display: none !important;
    }
    .logomobile {
      display: flex !important;
    }
    .btn-hide {
      display: none;
    }
    .mobile-menu {
      display: flex;
      align-items: center;
      background-color: #015259;
      justify-content: space-around;
      height: 8vh;
      width: 100%;
      .btn-language {
        position: unset;
      }
      .list {
        display: none !important;
      }
    }
    .notmobile {
      display: none;
    }
  }
`;

const WrapperMenu = styled.div`
  display: none;
  .btn-language {
    display: flex;
    position: absolute;
    left: 5%;
    bottom: 3%;
    background-color: white;
    width: 26%;
    height: 3.5vh;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    gap: 4px;
    padding: 5px;
    cursor: pointer;
    div {
      background-color: white;
      width: 100%;
      border-radius: 10px;
      text-align: center;
    }
    .active {
      background-color: #077983 !important;
      color: white;
    }
  }
  .logo {
    padding: 10px !important;
    height: 12% !important;
    img {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
  @media screen and (max-width: 624px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0 !important;
    height: 8vh;
    z-index: 99;
    /* top: 30%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: none;

    .logo {
      display: none !important;
    }
    .logomobile {
      display: flex !important;
    }
    .btn-hide {
      display: none;
    }
    .mobile-menu {
      display: flex;
      align-items: center;
      background-color: #015259;
      justify-content: space-around;
      height: 8vh;
      width: 100%;
      .btn-language {
        position: unset;
      }
      .list {
        /* display: none!important; */
      }
    }
    .notmobile {
      display: none;
    }
  }
`;

export default Menusidebar;
