import React, { useRef } from "react";
import styled from "styled-components";
import testImage from "../assets/images/Loading.png";
import mapimg from "../assets/images/map.jpeg";
import bubleimg from "../assets/images/iconvitri2.png";
import useKrpano from "react-krpano-hooks";
import { MapInteractionCSS } from "react-map-interaction";
import mapimg1 from "../assets/images/sodovitridasua.jpg";
import icon from "../assets/Icons/location.svg"



// import "./index";
function ImageLocation() {
  const refContainer = useRef();
  const refJam = useRef();
  return (
    <Wrapper>
      <div className="image-360">
        <MapInteractionCSS>
          <img src={mapimg1} />
          <section  className="iconlocation">
          

          <img className="iconbuilding"  style={{width :"120%",height : "120%"}} src={icon} />
       
      {/* <svg width="36" fill="#FFD372" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
      
        <path d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z"/></svg> */}

        
        

     

           
           
           
          </section>
          <section className="buble-box">
            <span style={{ animationDelay: "0s" }}></span>
            <span style={{ animationDelay: "1s" }}></span>
            <span style={{ animationDelay: "2s" }}></span>
            <span style={{ animationDelay: "3s" }}></span>
          </section>
        </MapInteractionCSS>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  height: 92.6vh;
  z-index: -1;

  .image-360 {
    width: 100%;
    height: 100%;
    background-color: white;
    div {
      div {
        div {
          height: 100%;
          img {
            height: 100%;
            min-width: 100vw;
          }
        }
      }
    }

    z-index: 1;
  }
  .iconlocation {
    position: absolute;
  
    width: 60px;
    height: 60px;
    background-color: transparent;
    right: 99.5%;
    bottom: 41%;
   
    
    animation: Nhay 3s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
    img{
      width: 100px;
      height: 100px;
    }
     
    
  }
  .buble-box {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 50%;
    bottom: 34%;
    animation: Nhay 3s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);

    span {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      pointer-events: none;
      background-color: #fff;
      opacity: 0;
      animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
    }
  }
  @media screen and (max-width: 1468px) {
    .image-360 {
      div {
        div {
          div {
            img {
              /* transform: translateX(-13%)!important; */
            }
          }
        }
      }
    }
    .iconlocation{
      right: 99%;
    }
  }
  @media screen and (max-width: 1168px) {
    .buble-box {
      right: 69%;
    }
    .iconlocation{
      right: 97%;
    }
    .image-360 {
      div {
        div {
          div {
            img {
              transform: translateX(-20%) !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width : 1080px){
    .iconlocation{
      right: 96%;
    }
  }

  @media screen and (max-width: 1024px) {
    .buble-box {
      right: 68%;
    }
    .iconlocation{
      right: 89%;
    }
    .image-360 {
      div {
        div {
          div {
            img {
              transform: translateX(-19%) !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 978px) {
    .buble-box {
      right: 73%;
    }
    .iconlocation{
      right: 96%;
    }
    .iconbuilding{
      width: 128%!important;
      height: 128%!important;
    }
    .image-360 {
      div {
        div {
          div {
            img {
              transform: translateX(-23%) !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .buble-box {
      right: 78%;
    }
    .iconlocation{
      right: 90%;
    }

    .image-360 {
      div {
        div {
          div {
            img {
              transform: translateX(-29%) !important;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 568px) {
    .iconbuilding{
      width: 110%!important;
      height: 110%!important;
    }
    .buble-box {
      right: 78%;
    }
    .iconlocation{
      right: 89%;
    }
  
  }
  @media screen and (max-width: 428px) {
    .buble-box {
      right: 84%;
    }
    .iconlocation{
      right: 89%;
    }
    .image-360 {
      div {
        div {
          div {
            img {
              transform: translateX(-35%) !important;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 428px) {
    .iconbuilding{
      width: 110%!important;
      height: 110%!important;
    }
    .iconlocation{
      right: 88.5%;
    }
   
  }
`;
export default ImageLocation;
