import React, { useContext, useEffect, useReducer, useState } from "react";
import reducer from "../reducers/tumyreducer";

import {} from "../utils/actions";
import icontour360 from "../assets/Icons/tour1.png";
import icontour360hover from "../assets/Icons/tour2.png";
import iconutility from "../assets/Icons/tool1.png";
import iconutilityhover from "../assets/Icons/tool2.png";
import houseicon from "../assets/Icons/house1.png";
import houseiconhover from "../assets/Icons/house2.png";
import locationicon from "../assets/Icons/pin1.png";
import locationiconhover from "../assets/Icons/pin2.png";
import useKrpano from "react-krpano-hooks";
import Loading from "../assets/images/Loading.png"

const initialState = {
  guide: false,
  currentscene: null,
  menu : true,
};

const TumysContext = React.createContext();

export const TumysProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [english,setEnglish] = useState(false);

  
  



  const ShowGuide = () => {
  
    dispatch({ type: "GUIDE_SHOW" });
  };
  const HideGuide = () => {
    

    dispatch({ type: "GUIDE_HIDE" });
  };
  const updateCurrentScene = (scene) => {
    dispatch({
      type: "UPDATE_SCENE",
      payload: { scene },
    });
  };
  const HideMenu = ()=>{
    dispatch({
      type : "HIDE MENU"
    })
  }
  const ShowMenu = ()=>{
    dispatch({
      type : "SHOW MENU"
    })
  }

 

  useEffect(() => {}, []);
  return (
    <TumysContext.Provider
      value={{
        ...state,
        HideGuide,
        ShowGuide,
        updateCurrentScene,
        HideMenu,
        ShowMenu,
        english,

        setEnglish
        
      }}
    >
      {children}
    </TumysContext.Provider>
  );
};
// make sure use
export const useTumysContext = () => {
  return useContext(TumysContext);
};
