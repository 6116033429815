import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SharedLayout from "./pages/SharedLayout";
import Loading from "./pages/Loading";
import Tour from "./pages/Tour";
import Utility from "./pages/Utility";
import HouseSample from "./pages/HouseSample";
import Location from "./pages/Location";
import "./App.css";
import { useEffect, useState } from "react";
import { useTumysContext } from "./context/tumycontext";
import Error from "./pages/Error";
import Phankhu from "./pages/Phankhu";

function App() {
  const [loading, setLoading] = useState(true);

  const { HideGuide, ShowGuide, updateCurrentScene, currentscene, guide } =
    useTumysContext();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  //  const {guide,ShowGuide,HideGuide} = useTumysContext();
  // if(loading){
  //   return (
  //     <Loading />
  //   )
  useEffect(() => {
    ShowGuide();
  }, []);
  // }

  const url = `https://360.goku.agency/tienthanh360/TienThanh360`;
  useEffect(()=>{
 updateCurrentScene(`${url}/index.html?startscene=scene_qqieycyjm_2_513_400m`)
   
  },[])

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" index element={<Loading />} />
          {/* <Route path="/tumys" element={<Test />} /> */}
          <Route element={<SharedLayout />}>
            <Route index path="/tour360" element={<Tour />} />
            {/* <Route path="/kcntienthanh/phankhu" element={<Phankhu />} />

            <Route path="/kcntienthanh/tienich" element={<Utility />} /> */}
            {/* <Route
              path="/kcntienthanh/tienich"
              element={
                <HouseSample
                
                  
                />
              }
            />
            <Route
              path="/tumys/location"
              element={
                <Location
                
                />
              }
            /> */}
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
