import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Guide from "../components/Guide";
import Mode from "../components/Mode";
import Loading from "../assets/images/bgloading.png"
import map from "../assets/images/map.jpeg"
import map1 from "../assets/images/map1.png"
// import ex1 from "../assets/images/toancanhkcntienthanh.png"
// import ex2 from "../assets/images/ex2.jpeg"
// import ex3 from "../assets/images/ex3.jpeg"
// import ex4 from "../assets/images/ex4.jpeg"
import Iframe from 'react-iframe'






import useKrpano from "react-krpano-hooks";
import Image360 from "../components/Image360";
import {useTumysContext} from "../context/tumycontext"

function Tour() {
  
  // const [isLoading,setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(1);
 
  const { HideGuide,
    ShowGuide,
    updateCurrentScene,currentscene,guide }  = useTumysContext();




//   useEffect(()=>{
//      ShowGuide();
// },[])



  

  



  // const data = [
  //   {
  //     id: 1,
  //     text: "VIEW DỰ ÁN 1",
  //     scene: ex1,
  //   },
  //   {
  //     id: 2,
  //     text: "VIEW DỰ ÁN 2",
  //     scene: ex2,
  //   },
  //   {
  //     id: 3,
  //     text: "VIEW DỰ ÁN 3",
  //     scene: ex3,
  //   },
  
  //   {
  //     id: 5,
  //     text: "VIEW TẦNG 25",
  //     // scene: `${url}/tumys/can54_59/index.html?startscene=scene_54_59-Cuavao`,
  //     scene: ex4,

  //   },
   
  // ];

  // const url  = `https://${window.location.hostname}`
  

  // useEffect(() => {
  //   updateCurrentScene(`${url}/tumys/can54_59/index.html?startscene=scene_54_59-Cuavao`);
  // }, []);


  return (
    <Wrapper>
      {/* {guide && <Guide ShowGuide={ShowGuide} HideGuide={HideGuide} /> } */}
      {/* <Image360 currentscene={currentscene} setCurrentscene={setCurrentscene} containerRef={containerRef}/> */}
     {/* <div ref={containerRef} style={{ width: '100%', height: '100%' }} />   */}
     {/* <img src={currentscene}  style={{ width: '100%', height: '100%' }} /> */}

     <Iframe url={currentscene}
        width="100%"
        height="100%"
        id=""
        className=""
        display="block"
        />
      
      

      {/* <Mode
        data={data}
        // setCurrentscene={setCurrentscene} 
        // loadnewscene={loadnewscene}
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
        width="55%"
        flex="auto"
      /> */}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  width: 100vw;
  z-index: 0;
`;

export default Tour;
