import React from 'react';
import ReactDOM from 'react-dom/client';
import { TumysProvider } from './context/tumycontext';
import App from './App';
import './assets/Fonts/00186-UTM-Trajan-Pro-Bold.ttf'
import './assets/Fonts/SVN-Gotham Bold.ttf'
import './assets/Fonts/SVN-Gotham Light.ttf'
import './assets/Fonts/font-poppins/Poppins-Regular.otf'
import './assets/Fonts/font-poppins/Poppins-Bold.otf'
import './assets/Fonts/font-poppins/Poppins-Medium.otf'





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TumysProvider>

    <App />
    </TumysProvider>
  

   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

