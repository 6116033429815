import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Guide from "../components/Guide";
import Mode from "../components/Mode";
import { useTumysContext } from "../context/tumycontext";
import useKrpano from "react-krpano-hooks";
import Image360 from "../components/Image360";
import Iframe from 'react-iframe'
function HouseSample() {
  const [currentIndex, setCurrentIndex] = useState(1);
  const { HideGuide,
    ShowGuide,
    updateCurrentScene,currentscene,guide }  = useTumysContext();
  // const url  = `https://${window.location.hostname}`

  const url = `https://360.goku.agency`;
  

  useEffect(() => {
    updateCurrentScene(`${url}/tumys/can54_59/index.html?startscene=scene_54_59-Cuavao`);
  }, []);


  const data = [
    {
      id: 1,
      text: "CĂN 2 PN",
      scene: `${url}/tumys/can54_59/index.html?startscene=scene_54_59-Cuavao`,
    },
   
    {
      id: 2,
      text: "CĂN 2 PN + 1",
      scene: `${url}/tumys/can68_73/index.html?startscene=scene_68_73-Loivao`,
    },
  ];
  return (
    <Wrapper>
      {/* <Image360 currentscene={currentscene} containerRef={containerRef} /> */}
      {/* <div ref={containerRef} style={{ width: "100%", height: "100%" }} /> */}
      <Iframe url={currentscene}
        width="100%"
        height="100%"
        id=""
        className=""
        display="block"
        />

      <Mode
        data={data}
        
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
        width="30%"
        flex="50%"
      />
      {/* {guide && <Guide /> } */}
      {guide && <Guide ShowGuide={ShowGuide} HideGuide={HideGuide} /> }

    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  width: 100vw;
`;
export default HouseSample;
