import React, { useEffect, useState } from "react";
import useKrpano from "react-krpano-hooks";
import styled from "styled-components";
import Guide from "../components/Guide";
import Image360 from "../components/Image360";
import Mode from "../components/Mode";
import { useTumysContext } from "../context/tumycontext";
import Logo from "../assets/images/logo.png"
import ex5 from "../assets/images/phankhutienthanh.png"
import ex6 from "../assets/images/ex6.jpeg"
import ex7 from "../assets/images/ex7.jpeg"
import ex8 from "../assets/images/ex8.jpeg"
import ex9 from "../assets/images/ex9.jpeg"
import ex10 from "../assets/images/ex10.jpeg"


function Phankhu() {


  const [currentIndex, setCurrentIndex] = useState(1);
  const { HideGuide,
    ShowGuide,
    updateCurrentScene,currentscene,guide }  = useTumysContext();
 

//  useEffect(()=>{
//     updateCurrentScene("scene_Cayjng_Caybi_Meybp")
//  },[containerRef])
useEffect(()=>{
  updateCurrentScene(ex5)
},[])

  const data = [
    {
      id: 1,
      text: "PHÂN KHU A",
      scene: ex5,
    },
    {
      id: 2,
      text: "PHÂN KHU B",
      // scene: `${url}/tumys/can54_59/index.html?startscene=scene_54_59-Cuavao`,
      scene: ex6,

    },
    {
      id: 3,
      text: "PHÂN KHU C",
      scene: "scene_KCN_Phuyb_Myyd_2",
      scene: ex7,

    },
    {
      id: 4,
      text: "PHÂN KHU D",
      scene: ex8,

    },
    {
      id: 5,
      text: "KHU NHÀ Ở CÔNG NHÂN",
      scene: ex9,
      
    },
    // {
    //   id: 6,
    //   text: "TRUNG TÂM HÀNH CHÍNH",
    //   scene: ex10,

    // },
  ];
  // const url  = `https://${window.location.hostname}`
  

  // useEffect(() => {
  //   updateCurrentScene(`${url}/tumys/can54_59/index.html?startscene=scene_54_59-Cuavao`);
  // }, []);
  return (
    <Wrapper>
      {/* <Image360 currentscene={currentscene} containerRef={containerRef} /> */}
     {/* <div ref={containerRef} style={{ width: '100%', height: '100%' }} />   */}
     <img src={currentscene}  style={{ width: '100%', height: '100%' }} />

      <Mode
         data={data}
        
         setCurrentIndex={setCurrentIndex}
         currentIndex={currentIndex}
        width="85%"
        flex="auto"
      />
           {guide && <Guide ShowGuide={ShowGuide} HideGuide={HideGuide} /> }

    </Wrapper>
  );
}
const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  width: 100vw;
`;
export default Phankhu;
